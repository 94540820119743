/*
  ```
    DrbToast.open({
      title: 'Success',
      messageHtml: 'Your changes have been saved.',
    });

    // w/ icon
    import checkIcon from '~/assets/icons/check-verified.svg?raw';

    DrbToast.open({
      title: 'Success',
      messageHtml: 'Your changes have been saved.',
      iconHtml: checkIcon,
    });

*/

export const DrbToast = {
  open({
    id = '',
    iconHtml = '',
    title = '',
    messageHtml = '',
    variant = 'primary',
    onlyOnce = false,
    duration,
  }) {
    const toast = Object.assign(document.createElement('drb-toast'), {
      id,
      duration,
      title,
      variant,
      onlyOnce,
      innerHTML: `
        <div slot="icon">${iconHtml}</div>
        <div slot="message">${messageHtml}</div>
      `,
    });

    document.body.append(toast);
  },
};
