// Binding to document to pick up items added to DOM afterward, a la jQuery's `.live()`.
document.addEventListener('submit', (event) => {
  if (event.target.closest('form[data-on-submit-update-dom]')) {
    const form = event.target;
    const domTarget = document.querySelector(form.dataset.onSubmitUpdateDom);
    if (domTarget) {
      event.preventDefault();
      const data = new FormData(form);

      fetch(form.action, {
        method: data.get('_method') || form.method,
        body: data,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
        .then((response) => response.text())
        .then((body) => {
          domTarget.outerHTML = body;
        })
        .catch((error) => Dribbble?.Notify?.error(error));
    }
  }
});
