/* eslint-disable arrow-body-style */
import Flickity from 'flickity';
import 'flickity/css/flickity.css';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipeDrbVideoPlugin from '~/globals/photoswipe-plugins/drb-video-photoswipe-plugin';
import { PHOTOSWIPE_ICONS } from '~/shared/modules/photoswipe';
import { generateSrcset } from '~/shared/utils/media';
import { ProjectCostBreakdown } from '~/marketplace/project-cost-breakdown';
import { PristineValidation } from '~/globals/form-validation/form-validation';

export const ServiceView = {
  init() {
    // Initialize service dialogs
    document.addEventListener('drb-dialog-content-updated', (e) => {
      const dialog = e.target.closest('drb-dialog#service-view-dialog');
      if (!dialog) return;

      this.initFlickity(dialog);
      this.bindThumbnails(dialog);
      this.initLightbox(dialog);
      this.bindCTAButtons(dialog);
      this.bindSelectPaymentMethod(dialog);
      this.bindDialogValidation(dialog);
    });

    // Pause all videos when the dialog is closed
    document.addEventListener('drb-dialog-closed', (e) => {
      const dialog = e.target.closest('drb-dialog#service-view-dialog');
      if (!dialog) return;

      dialog.querySelectorAll('video').forEach((videoElement) => {
        videoElement.pause();
      });
    });

    // Intialize service show pages
    const servicePageContainer = document.querySelector('[data-service-view-page]');

    if (servicePageContainer) {
      this.initFlickity(servicePageContainer);
      this.bindThumbnails(servicePageContainer);
      this.initLightbox(servicePageContainer);
      this.bindCTAButtons(servicePageContainer);
      this.bindSelectPaymentMethod(servicePageContainer);
      this.bindDialogValidation(servicePageContainer);
    }

    // Initialize Project Cost Breakdown
    ProjectCostBreakdown.init();
  },

  // We only want a single video to play at a time within the carousel, and
  // prevent playing videos when the lightbox is open.
  playPauseCarouselVideos(contextElement) {
    const currentSlideVideo = contextElement.flkty?.selectedElement?.querySelector('video');
    const lightboxIsOpen = contextElement.lightbox?.pswp?.isOpen;

    contextElement.querySelectorAll('video').forEach((slideVideo) => {
      if (slideVideo === currentSlideVideo && !lightboxIsOpen) {
        // play video if the current slide is a video and the lightbox is not open
        slideVideo.play();
      } else {
        // otherwise pause all other videos
        slideVideo.pause();
      }
    });
  },

  initFlickity(contextElement) {
    const carousel = contextElement.querySelector('[data-service-view-carousel]');
    if (!carousel) return;

    const hasMultipleImages = contextElement.querySelectorAll('[data-service-view-gallery-thumbnail]').length > 1;
    const isTouchDevice = matchMedia('(hover: none)').matches;

    if (contextElement.flkty) contextElement.flkty.destroy();

    contextElement.flkty = new Flickity(carousel, {
      wrapAround: true,
      contain: true,
      prevNextButtons: hasMultipleImages,
      pageDots: false,
      draggable: hasMultipleImages && isTouchDevice,
    });

    contextElement.flkty.on('change', (index) => {
      // sync thumbnail buttons on change (dragging carousel or clicking thumbnails)
      contextElement.querySelectorAll('[data-service-view-gallery-button]').forEach((button, buttonIndex) => {
        button.toggleAttribute('data-current', index === buttonIndex);
      });

      this.playPauseCarouselVideos(contextElement);
    });

    // open lightbox when clicking a slide
    contextElement.flkty.on('staticClick', (event, _pointer, cellElement, cellIndex) => {
      // don't open the lightbox when interacting with the drb-video controls
      if (event.target.tagName === 'DRB-VIDEO') return;

      // otherwise, open the lightbox
      contextElement.lightbox?.loadAndOpen(cellIndex);
    });

    // Add an attribute on the next frame (which we can use to show/hide the arrows)
    // to prevent them from appearing abruptly when the carousel is initialized
    // preserving the transition effect.
    setTimeout(() => {
      carousel.toggleAttribute('data-carousel-initialized', true);
    }, 0);
  },

  bindThumbnails(contextElement) {
    contextElement.querySelectorAll('[data-service-view-gallery-button]').forEach((button, buttonIndex) => {
      button.addEventListener('click', () => {
        contextElement.flkty?.selectCell(buttonIndex);
      });
    });
  },

  initLightbox(contextElement) {
    if (contextElement.lightbox) contextElement.lightbox.destroy();

    // append lightbox to dialog if it exists, otherwise append to body (prevent rendering behind dialog)
    const appendToEl = contextElement.querySelector('[slot="dialog-outer"]') || document.body;

    contextElement.lightbox = new PhotoSwipeLightbox({
      secondaryZoomLevel: 'fill',
      doubleTapAction: false,
      arrowPrev: false,
      arrowNext: false,
      appendToEl,
      dataSource: [],
      ...PHOTOSWIPE_ICONS,
      pswpModule: () => import('photoswipe'),
    });

    // set photoswipe thumbEl to enable animations
    contextElement.lightbox.addFilter('thumbEl', (thumbEl, data, index) => {
      return contextElement.querySelectorAll('[data-lightbox-trigger]')[index] || thumbEl;
    });

    // sync flickity carousel with photoswipe
    contextElement.lightbox.on('change', () => {
      const { currIndex } = contextElement.lightbox.pswp;
      contextElement.flkty?.selectCell(currIndex);
    });

    // when the lightbox is opened/closed, play/pause carousel videos accordingly
    contextElement.lightbox.on('openingAnimationStart', () => {
      this.playPauseCarouselVideos(contextElement);
    });

    contextElement.lightbox.on('destroy', () => {
      setTimeout(() => {
        this.playPauseCarouselVideos(contextElement);
      }, 0);
    });

    new PhotoSwipeDrbVideoPlugin(contextElement.lightbox);
    contextElement.lightbox.init();

    // set the lightbox item data
    contextElement.querySelectorAll('[data-lightbox-trigger]').forEach((element) => {
      const lightboxItemData = this.getPhotoswipeData(element);
      if (lightboxItemData) contextElement.lightbox.options?.dataSource?.push(lightboxItemData);
    });
  },

  getPhotoswipeData(element) {
    const mediaWidth = parseInt(element.getAttribute('data-media-width') || 400, 10);
    const mediaHeight = parseInt(element.getAttribute('data-media-height') || 300, 10);
    const mediaType = element.getAttribute('data-media-type');
    const lightboxUrl = element.getAttribute('data-lightbox-url');
    const previewUrl = element.getAttribute('data-preview-url');
    const fileName = element.getAttribute('data-file-name');

    const isValidLightboxItem = !!previewUrl && !!lightboxUrl && !!mediaHeight && !!mediaWidth;
    const isGif = fileName.endsWith('.gif');

    if (!isValidLightboxItem) return null;

    // video previews
    if (mediaType === 'video') {
      return {
        videoSrc: lightboxUrl,
        type: 'drb-video',
        msrc: previewUrl,
        width: mediaWidth,
        height: mediaHeight,
        thumbCropped: true,
      };
    }

    // image previews
    const widths = [mediaWidth, 640, 850, 1024, 1600, 1905, 2400, 3810].filter((width) => width <= mediaWidth);

    const srcset = !isGif
      ? generateSrcset({ mediaUrl: lightboxUrl, aspectRatio: mediaWidth / mediaHeight, widths })
      : null;

    return {
      srcset,
      src: lightboxUrl,
      msrc: previewUrl,
      width: mediaWidth,
      height: mediaHeight,
      thumbCropped: true,
    };
  },

  bindCTAButtons(container) {
    const toggleButtons = container?.querySelectorAll('[data-service-view-toggle-panel]');
    const panels = container?.querySelectorAll('[data-service-view-panel]');

    toggleButtons.forEach((button) => {
      button.addEventListener('click', () => {
        panels.forEach((panel) => {
          panel.toggleAttribute('hidden');
        });
      });
    });
  },

  bindSelectPaymentMethod(dialog) {
    const achPaymentsSelect = dialog?.querySelector('[data-project-breakdown-payment-method-select]');

    achPaymentsSelect?.addEventListener('change', () => {
      this.updateSelectedPaymentMethod(dialog, achPaymentsSelect.value);
    });
  },

  updateSelectedPaymentMethod(dialog, paymentMethod) {
    const costBreakdownContainer = dialog?.querySelector('[data-project-breakdown-filter-payment-method]');

    costBreakdownContainer?.setAttribute('data-project-breakdown-filter-payment-method', paymentMethod);
  },

  bindDialogValidation(dialog) {
    const submitButton = dialog.querySelector('[data-checkout-button]');

    dialog.pristineValidation = PristineValidation(dialog);

    submitButton?.addEventListener('click', (e) => {
      if (!dialog.pristineValidation.validate()) {
        e.preventDefault();
      }
    });
  },
};
