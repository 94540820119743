import { PristineValidation } from '~/globals/form-validation/form-validation';
import { InternalTracking } from '~/shared/modules/internal-tracking';
import isFeatureGateEnabled from '~/shared/utils/feature-gates';
import {
  toggleShakeAnimation, toggleContactInfoWarning, bindContactInfoErrorOnValidation,
} from '~/marketplace/contact-info-detection-helpers';
import { trackGTMEvent } from '~/shared/utils/tracking/google-tag-manager-utils';

export const MessageDialog = {
  // global listeners for message dialog related actions
  bindListeners() {
    this.bindMessageInputSync();
    this.bindTabNavigation();
    this.bindFormValidation();
    this.bindTrackingListeners();

    // TODO: ticket DRB-21305
    if (!isFeatureGateEnabled('disintermediation')) {
      this.bindContactInfoResetLegacy();
      this.bindSendAnywayBtnLegacy();
    }
  },

  bindTabNavigation() {
    document.addEventListener('click', (e) => {
      const targetTab = e.target.closest('[data-message-dialog-tab]');
      if (!targetTab) return;

      document.querySelectorAll('[data-message-dialog-tab]').forEach((tab) => {
        tab.classList.toggle('active', tab === targetTab);
      });
    });
  },

  bindMessageInputSync() {
    document.addEventListener('input', (e) => {
      const messageDialog = e.target.closest('drb-dialog#message-dialog');
      if (!messageDialog) return;

      const messageInputs = messageDialog.querySelectorAll('#message-dialog-message, #message-dialog-details');
      if (!Array.from(messageInputs).includes(e.target)) return;

      messageInputs.forEach((input) => {
        input.value = e.target.value;
      });
    });
  },

  bindFormValidation() {
    // bind form validation to message dialog forms when they are loaded
    document.addEventListener('drb-dialog-content-updated', (e) => {
      const messageDialog = e.target.closest('drb-dialog#message-dialog');
      if (!messageDialog) return;

      messageDialog.querySelectorAll('[data-message-dialog-form]').forEach((form) => {
        form.pristineValidation = PristineValidation(form);

        if (isFeatureGateEnabled('disintermediation')) {
          bindContactInfoErrorOnValidation(form);
        }
      });
    });

    // validate the form on submit
    document.addEventListener('submit', (e) => {
      const form = e.target.closest('[data-message-dialog-form]');
      if (!form) return;

      e.preventDefault();

      // TODO: ticket DRB-21305
      if (!isFeatureGateEnabled('disintermediation')) {
        const isSharingContactInfo = this.checkForContactInfoLegacy(e);
        if (isSharingContactInfo) {
          this.handleContactInfoDetectedLegacy(isSharingContactInfo);
          return;
        }
      }

      // submit the form if valid and not already submitting
      if (!form.hasAttribute('is-submitting') && form.pristineValidation?.validate()) {
        this.submitForm(form);
      }
    });
  },

  bindTrackingListeners() {
    document.addEventListener('drb-dialog-content-updated', (e) => {
      const messageDialog = e.target.closest('drb-dialog#message-dialog');
      if (!messageDialog) return;

      const form = messageDialog.querySelector('[data-message-dialog-form]');
      const userId = form?.querySelector("input[name='engagement[recipient_id]']")?.value;
      if (!userId) return;

      InternalTracking.trackSearchMessageModalDisplayed(userId, messageDialog);

      trackGTMEvent('MessageModalDisplayed', {
        userId,
      });
    });
  },

  // TODO: ticket DRB-21305
  bindContactInfoResetLegacy() {
    document.addEventListener('input', (e) => {
      const messageDialog = e.target.closest('drb-dialog#message-dialog');
      if (!messageDialog) return;

      const isSharingContactInfo = this.checkForContactInfoLegacy(e);

      if (!isSharingContactInfo) {
        this.handleContactInfoDetectedLegacy(isSharingContactInfo);
      }
    });
  },

  async submitForm(form) {
    const defaultServerErrorMsg = 'Failed to send message';
    const submitBtn = form.querySelector('[data-message-dialog-submit]');
    const formError = form.querySelector('[data-form-error]');

    // disable the submit button and show loading state
    const toggleFormSubmitting = (isSubmitting) => {
      form.toggleAttribute('is-submitting', isSubmitting);
      submitBtn.disabled = isSubmitting;
      submitBtn.classList.toggle('btn2--loading', isSubmitting);
    };

    // incase of server error, show error message
    const setServerErrorMsg = (errorMsg) => {
      if (!formError) return;
      formError.toggleAttribute('hidden', !errorMsg);
      formError.innerText = errorMsg;
    };

    // reset state
    toggleFormSubmitting(true);
    setServerErrorMsg(null);

    try {
      const response = await fetch(form.getAttribute('action'), {
        method: 'POST',
        body: this.getTransformedFormData(form),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });

      const responseText = await response.text();

      if (!response.ok) {
        // use error message provided by BE if available (422), otherwise use default
        const errorMsg = response.status === 422 ? responseText : defaultServerErrorMsg;
        throw new Error(errorMsg || defaultServerErrorMsg);
      }

      // track `UserMessaged` event
      const recipientId = form.querySelector('[data-recipient-id-input]')?.value;
      if (recipientId) InternalTracking.trackSearchUserMessaged(recipientId);

      trackGTMEvent('UserMessaged', {
        recipientId,
      });

      // update the dialog content with the response (e.g. success message, etc.)
      const dialog = form.closest('drb-dialog');
      dialog?.setContent(responseText);
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : defaultServerErrorMsg;
      setServerErrorMsg(errorMsg);
      console.error(errorMsg, error);
    } finally {
      toggleFormSubmitting(false);
    }
  },

  getTransformedFormData(form) {
    const formData = new FormData(form);

    switch (form.getAttribute('data-message-dialog-form')) {
      case 'workRequest':
        formData.set('engagement[budget_cents]', parseInt(formData.get('budget_dollars') || 0, 10) * 100);
        formData.delete('budget_dollars');
        break;

      default:
        break;
    }

    return formData;
  },

  // TODO: ticket DRB-21305
  bindSendAnywayBtnLegacy() {
    document.addEventListener('click', (e) => {
      // TODO: ticket DRB-21305
      const messageDialog = e.target.closest('drb-dialog#message-dialog');
      if (!messageDialog) return;

      const sendAnywayBtn = e.target.closest('[data-contact-info-warning-send-btn]');
      if (!sendAnywayBtn) return;

      const form = messageDialog.querySelector('[data-message-dialog-form]');
      this.handleContactInfoDetectedLegacy(false);
      this.submitForm(form);
    });
  },

  // TODO: ticket DRB-21305
  getContactInfoRegexesLegacy() {
    const form = document.querySelector('[data-message-dialog-form="workRequest"]');
    const regexes = form?.getAttribute('data-contact-info-regexes');
    if (!regexes) return;

    // eslint-disable-next-line consistent-return
    return JSON.parse(regexes).map((regex) => new RegExp(regex, 'i'));
  },

  // TODO: ticket DRB-21305
  checkForContactInfoLegacy(e) {
    const form = e.target.closest('[data-message-dialog-form="workRequest"]');
    if (!form) return;

    const detailsInput = form.querySelector('#message-dialog-details');

    const regexes = this.getContactInfoRegexesLegacy();
    const isSharingContactInfo = regexes.some((regex) => regex.test(detailsInput.value));
    // eslint-disable-next-line consistent-return
    return isSharingContactInfo;
  },

  // TODO: ticket DRB-21305
  handleContactInfoDetectedLegacy(isDetected) {
    const form = document.querySelector('[data-message-dialog-form="workRequest"]');
    const warningElement = form?.querySelector('[data-contact-info-warning]');
    if (!warningElement) return;

    const submitBtn = form.querySelector('[data-message-dialog-submit]');

    toggleContactInfoWarning(warningElement, isDetected);
    toggleShakeAnimation(submitBtn, isDetected);
  },
};
