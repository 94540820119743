/*
  * PhotoSwipe Drb Video Plugin
  * Allows using our custom video player ui in the PhotoSwipe lightbox.

  * The source code is almost entirely copied from the PhotoSwipeVideoPlugin
  * and modified for custom drb-video player.
  * @see https://github.com/dimsemenov/photoswipe-video-plugin/
*/

import VideoContentSetup from './drb-video-content-setup.js';

const defaultOptions = {
  videoAttributes: {
    playsinline: '', preload: 'auto', loop: '',
  },

  autoplay: true,

  // prevent drag/swipe gesture over the bottom part of video
  // set to 0 to disable
  preventDragOffset: 80,
};

class PhotoSwipeDrbVideoPlugin {
  constructor(lightbox, options) {
    new VideoContentSetup(lightbox, {
      ...defaultOptions,
      ...options,
    });
  }
}

export default PhotoSwipeDrbVideoPlugin;
